<template>
  <Teleport to="body">
    <HocModalBox
      v-if="modelValue"
      :can-close="false"
      :model-width="`max-w-md`"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative my-6 mx-auto max-w-mb w-[500px]">
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <div class="relative p-6 flex-auto">
            <div class="flex flex-col items-center justify-center rounded-t">
              <SvgSprite :class="info?.iconClass" :symbol="info?.icon" />
              <h3 class="text-base text-gray-700 2xl:text-xl font-semibold">
                {{ info?.title }}
              </h3>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="my-4 text-base text-gray-600 2xl:text-[22px] font-normal text-center"
              v-html="info?.description"
            ></p>
            <!--eslint-enable-->
            <div class="flex items-center justify-end rounded-b gap-3">
              <ElementsButtonsTheButton
                v-if="info?.showCancelBtn"
                :class="dangerBtnClasses"
                :element-id="`cancel-btn`"
                type="button"
                @click="(e) => $emit('handleRejectAction', e)"
                >{{ info?.rejectBtnLabel ?? $t('buttons.cancel') }}
              </ElementsButtonsTheButton>
              <ElementsButtonsTheButton
                :class="primaryBtnClasses"
                :element-id="`okay-btn`"
                type="button"
                @click="(e) => $emit('handleConfirmAction', e)"
                >{{ info?.acceptBtnLabel || $t('buttons.yes') }}
              </ElementsButtonsTheButton>
            </div>
          </div>
        </div>
      </div>
    </HocModalBox>
    <div v-if="modelValue" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </Teleport>
</template>

<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { ref } from 'vue';
import { dangerBtnClasses, primaryBtnClasses } from '@/utils/config';

const props = defineProps({
  details: {
    type: Object,
    required: false,
    default: () => undefined
  },
  modelValue: {
    type: Boolean,
    default: false
  }
});

const info = ref(props?.details);

defineEmits(['update:modelValue', 'handleConfirmAction', 'handleRejectAction']);
</script>
