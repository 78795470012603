import Validations from '@/validations/Validations';
import { PASSWORD_MAX_LENGTH } from '@/utils/constants';

export default class LoginValidation {
  constructor(email, password, realmId) {
    if (realmId) {
      this.realmId = realmId;
    }
    this.email = email;
    this.password = password;
  }

  checkValidations() {
    let errors = [];
    if (this.realmId && this.realmId === '') {
      if (Validations.checkEmpty(this.realmId)) {
        errors.realmId = 'inputs.errors.realm_id';
      }
    }
    if (Validations.checkEmpty(this.email)) {
      errors.email = 'inputs.errors.email.required';
    } else if (Validations.checkEmail(this.email)) {
      errors.email = 'inputs.errors.email.invalid';
    }
    if (Validations.checkEmpty(this.password)) {
      errors.loginPwd = 'inputs.errors.email.required';
    }

    if (
      !Validations.checkEmpty(this.password) &&
      (!Validations.minLength(this.password, PASSWORD_MAX_LENGTH) || !Validations.checkPassword(this.password))
    ) {
      errors.loginPwd = 'inputs.errors.password.login';
    }
    if (errors.loginPwd) {
      errors = { ...errors, password: errors.loginPwd };
      delete errors.loginPwd;
    }
    return errors;
  }
}
