<template>
  <input
    :id="id"
    :class="[
      disabled
        ? 'w-full button button--primary bg-blue-500 text-white py-1 rounded opacity-50 cursor-not-allowed'
        : getClass(background)
    ]"
    :disabled="disabled"
  />
</template>

<script setup>
defineProps({
  background: {
    type: String,
    default: 'button'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    required: true
  }
});
const getClass = (value) => value;
</script>
