const defaultInputClass = 'form-input';
const defaultDivClass = 'mb-0';

const loginData = {
  realmId: {
    type: 'select',
    id: 'realmId',
    options: () => [],
    optionKey: 'id',
    optionValue: 'name',
    imageKey: 'brand',
    isImage: true,
    divClass: defaultDivClass,
    inputClass: defaultInputClass,
    label: 'inputs.help.realm_id',
    errorMessageKey: '',
    error: false,
    value: ''
  },
  email: {
    type: 'email',
    id: 'email',
    divClass: defaultDivClass,
    inputClass: defaultInputClass,
    label: 'inputs.help.email',
    errorMessageKey: '',
    error: false,
    value: ''
  },
  password: {
    type: 'password',
    id: 'password',
    divClass: defaultDivClass,
    inputClass: defaultInputClass,
    label: 'inputs.help.password',
    errorMessageKey: '',
    error: false,
    value: ''
  }
};

export { loginData };
